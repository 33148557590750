<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <b-link class="brand-logo">
            <GoZayaanBrandLogo />
          </b-link>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import GoZayaanBrandLogo from '@/components/icons/GoZayaanBrandLogo.vue'

export default {
  components: {
    BLink,
    GoZayaanBrandLogo,
  },
  setup() {
    // App Name
    const { appName } = $themeConfig.app
    return {
      appName,
    }
  },
}
</script>
